<template>
  <div class="product-card">
    <div class="product-card-wrapper">
        <div class="product-img-wrapper">
          <!-- <img class="background" :src="productDetail.images[0].productImageUrl" /> -->
          <img class="product-img" style="object-fit: fill; position: absolute" :src="productDetail.images[0].productImageUrl" />
        </div>
        <div style="text-align: left; line-height: 1.7; padding-top: 10px" class="sub-label-light fs-16">
          <div>{{ productDetail.name }}</div>
          <div>{{ `${productLicense} ${productDetail.scale}` }}</div>
        </div>
        <div
          style="padding-bottom: 10px; text-align: right; line-height: 2.5"
          class="sub-header fs-16"
        >
          <div>SGD${{ productDetail.listingPrice.toLocaleString(undefined, {minimumFractionDigits: 2}) }}</div>
        </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    productDetail: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const productLicense = computed(() => {
      if (props.productDetail.characters.length === 0) {
        return '';
      }
      return props.productDetail.characters[0].license.name;
    });

    return {
      productLicense,
    };
  },
};
</script>
