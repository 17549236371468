<template>
  <section class="review-card" v-if="reviewee">
    <div class="reviewee-warpper">
      <el-image
        class="reviewee-image"
        :src="reviewee.displayPhotoFilePath ?? defaultProfilePic"
        fit="fill"
      ></el-image>
      <div class="reviewee-name">{{ reviewee.name }}</div>
    </div>
    <div class="review-wrapper">
      <el-rate v-model="reviewScore" disabled show-score text-color="#000000"></el-rate>
      <div class="review-comment">{{ reviewDetail.comment }}</div>
      <!-- <div v-if="product">
        <img :src="product.images[0].productImageUrl" width="100" height="100">

      </div>-->
      <div v-if="product" style="display: flex; flex-wrap: wrap;">
        <div>
          <el-image class="review-product-img" fit="cover" :src="product.images[0].productImageUrl"></el-image>
        </div>
        <div style="display: flex; flex-flow: column;">
          <div>
            <div>
              <div>{{ product.name }}</div>
              <div>{{ product.license }}, {{ product.scale }} Scale</div>
            </div>
          </div>
          <div style="margin-top: auto; text-transform: uppercase; font-weight: bold;">
            <div>{{ `SGD$${product.listingPrice.toLocaleString(undefined, {minimumFractionDigits: 2})}` }}</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, onMounted } from 'vue';
import profileServices from '@/services/profile-service';
import productServices from '@/services/product-service';

export default {
  props: {
    reviewDetail: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const defaultProfilePic = process.env.VUE_APP_DEFAULT_PIC_URL;
    const reviewee = ref(null);
    const product = ref(null);
    const reviewScore = ref(props.reviewDetail.score);

    onMounted(async () => {
      await Promise.all([
        profileServices.getProfilebyUserId(props.reviewDetail.createdByUserId),
        productServices.getProductById(props.reviewDetail.productId),
      ]).then(
        (result) => {
          reviewee.value = {
            ...result[0],
          };
          product.value = {
            ...result[1],
          };
        },
        () => {
          reviewee.value = null;
          product.value = null;
        },
      );
    });

    return {
      defaultProfilePic,
      reviewee,
      product,
      reviewScore,
    };
  },
};
</script>

<style lang="scss" scoped>
.review-card {
  display: flex;
  border: 1px solid #00000030;
  margin-top: 10px;
  padding: 40px;
  border-radius: 5px;
}

.reviewee-warpper {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 20px 40px 20px 20px;
  justify-content: center;
  width: 20%;
}

.reviewee-image {
  border-radius: 50%;
  width: 90px;
  height: 90px;
}

.reviewee-name {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.review-comment {
  padding: 10px 0;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.review-product-img {
  height: 100px;
  width: 100px;
  display: block;
  padding-right: 10px;
}

.review-wrapper {
  width: 80%;
}

@media screen and (max-width: 576px) {
  .reviewee-name {
    font-size: 16px;
  }

  .reviewee-image {
    width: 50px;
    height: 50px;
  }

  .review-card,
  .reviewee-warpper {
    padding: 10px;
  }

  .review-product-img {
    height: 80px !important;
    width: 80px !important;
  }
}
</style>
