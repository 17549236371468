<template>
  <div :span="24" :sm="{ span: 16 }" v-loading="gettingListingProduct">
    <template v-if="listingProduct.length > 0">
      <el-main style="position: relative;">
        <el-row :gutter="20">
          <el-col
            v-for="product in listingProduct"
            :key="product"
            :xs="12"
            :sm="12"
            :md="8"
            :lg="6"
            :xl="6"
          >
            <router-link :to="`/product/${product.productId}`">
              <ProductCard :productDetail="product" />
            </router-link>
          </el-col>
        </el-row>
      </el-main>
    </template>
    <template v-else>
      <el-empty description="No Records"></el-empty>
    </template>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import profileServices from '@/services/profile-service';
import productServices from '@/services/product-service';
import ProductCard from '@/components/Product/ProductCard.vue';

export default {
  name: 'Listings',
  setup() {
    const route = useRoute();
    const listingProduct = ref([]);
    const gettingListingProduct = ref(true);

    onMounted(async () => {
      gettingListingProduct.value = true;
      const profile = await profileServices.getProfilebyUserId(route.params.id);
      listingProduct.value = await productServices.getProductByUserId(profile.userId);
      gettingListingProduct.value = false;
    });

    return {
      listingProduct,
      gettingListingProduct,
    };
  },
  components: {
    ProductCard,
  },
};
</script>
